.container {
  min-height: 100vh;
  background: radial-gradient(82.61% 132.17% at 0% 0%, #BCE3FF 0%, #5396C7 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 365px;
  background: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  padding: 1.5rem;
  padding-top: 50px;
  position: relative;
  margin: 4rem 2rem 2rem 2rem;
}

input:not([type=checkbox]):not([type=radio]) {
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  color: rgba(0,0,0,.85);
  display: inline-block;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 4px 11px;
  position: relative;
  transition: all .3s;
  width: 100%;
  &::placeholder {
    color: #d9d9d9;
  }
  &:hover {
    border-color: #7bb2d4;
    border-right-width: 1px;
  }
  &:focus {
    border-color: #7bb2d4;
    border-right-width: 1px;
    box-shadow: 0 0 0 2px rgb(83 150 199 / 20%);
    outline: 0;
  }
  &.error {
    border-color: #ff4d4f;
    &:focus {
      box-shadow: 0 0 0 2px rgba(255, 77, 80, 0.2);
    }
  }
}

.input-group {
  position: relative;
  background: #fff;
  .input-prefix {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    line-height: 1.5715;
    border-right: 1px solid #d9d9d9;
    padding: 0 6px 1px 6px;
    width: 38px;
    +input {
      padding-left: 42px;
    }
  }
}

.input-password {
  position: relative;
  background: #fff;
  .password-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
    margin-right: 8px;
    cursor: pointer;
  }
  input {
    padding-right: 30px !important;
  }
}

.label-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .25rem;
}

.text-error {
  margin-top: .25rem;
  color: #ff4d4f;
}

button {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  line-height: 1.5715;
  padding: 4px 15px;
  text-align: center;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  border: 1px solid #d9d9d9;
  img {
    margin-right: 6px;
  }
  &:hover {
    background: #f7f7f7;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &.btn-primary {
    background: #5396c7;
    color: #fff;
    border: none;
    &:hover {
      background: #378bc6;
    }
    &:disabled {
      background: #7bb2d4;
      cursor: not-allowed;
    }
  }
  &.btn-secondary {
    background: #F0F6FA;
    border-color: #CADAE5;
    color: #5396C7;
    &:hover {
      background: #e5f1f8;
    }
    &:disabled {
      background: #f3f3f3;
      color:#a6a6a6;
    }
  }
}

.divider {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding: 0 .75rem;
  }
  &::before {
    position: relative;
    width: 50%;
    border-block-start: 1px solid #d9d9d9;
    border-block-start-color: #d9d9d9;
    border-block-end: 0;
    transform: translateY(50%);
    content: '';
  }
  &::after {
    position: relative;
    width: 50%;
    border-block-start: 1px solid #d9d9d9;
    border-block-start-color: #d9d9d9;
    border-block-end: 0;
    transform: translateY(50%);
    content: '';
  }
}

.avatar-card {
  position: absolute;
  background-color: #F0F6FA;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 1;
  top: -40px;
  left: 0;
  right: 0;
  border: 6px solid #fff;
  margin: auto;
}

.login-text {
  text-align: center; 
  font-size: 20px; 
  font-weight: 500;
  margin-bottom: .5rem;
}

.login-desc {
  text-align: center; 
  margin: 4px 0 20px 0;
  color: #757575;
}

a {
  color: #5396C7; 
  font-weight: 500;
  text-decoration: none;
  &:hover {
    color: #2882c3;
  }
}

.alert-error {
  color: #ff4d4f;
  background: #ff4d4f14;
  padding: 0.5rem;
  border-radius: 6px
}

.alert-success {
  color: #5db974;
  background: #7aea7d27;
  padding: 0.5rem;
  border-radius: 6px
}

.loader {
  margin-right: .5rem;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #7bb2d4;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.radio-wrapper {
  display: flex;
  align-items: center;
}
.radio-otp {
  display: flex;
  border: 1px solid #d9d9d9;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 8px;
  cursor: pointer;
  &.active {
    border-color: #5396c7;
  }
  &.disabled {
    background-color: #f4f4f4;
    cursor: not-allowed;
  }
}
.otp-desc {
  margin-top: 0.5rem;
  color: #757575;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  width: 1.2rem;
  height: 1.2rem;
  font: inherit;
  border: 0.13em solid #9E9E9E;
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin-right: 0.5rem;
  cursor: pointer;
  &.small {
    width: 1.1rem;
    height: 1.1rem;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
  }
}
input[type="radio"]::before {
  content: "";
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #5396c7;
  
}
input[type="radio"].small::before {
  width: 0.6rem;
  height: 0.6rem;
}
input[type="radio"]:checked::before {
  transform: scale(1);
}