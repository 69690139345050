body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.m-1 {
  margin: .25rem;
}
.m-2 {
  margin: .5rem;
}
.m-3 {
  margin: .75rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}
.mb-2 {
  margin-bottom: .5rem;
}
.mb-3 {
  margin-bottom: .75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-1 {
  margin-top: .25rem;
}
.mt-2 {
  margin-top: .5rem;
}
.mt-3 {
  margin-top: .75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}

.mr-1 {
  margin-right: .25rem;
}
.mr-2 {
  margin-right: .5rem;
}
.mr-3 {
  margin-right: .75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}

.ml-1 {
  margin-left: .25rem;
}
.ml-2 {
  margin-left: .5rem;
}
.ml-3 {
  margin-left: .75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}

.p-1 {
  padding: .25rem;
}
.p-2 {
  padding: .5rem;
}
.p-3 {
  padding: .75rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}
.pb-2 {
  padding-bottom: .5rem;
}
.pb-3 {
  padding-bottom: .75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}

.pt-1 {
  padding-top: .25rem;
}
.pt-2 {
  padding-top: .5rem;
}
.pt-3 {
  padding-top: .75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-5 {
  padding-top: 1.25rem;
}

.pr-1 {
  padding-right: .25rem;
}
.pr-2 {
  padding-right: .5rem;
}
.pr-3 {
  padding-right: .75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-5 {
  padding-right: 1.25rem;
}

.pl-1 {
  padding-left: .25rem;
}
.pl-2 {
  padding-left: .5rem;
}
.pl-3 {
  padding-left: .75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-5 {
  padding-left: 1.25rem;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}
.flex-gap {
  display: flex;
  gap: 12px;
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centered-x {
  display: flex;
  justify-content: center;
}
.centered-y {
  display: flex;
  align-items: center;
}